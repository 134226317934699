import { ListingDTO } from '@invenco/common-interface/sales';
import { LocationInventoryDTO } from '@invenco/common-interface/inventory';
import {
  FulfillmentStrategy,
  KitComponentDTO,
  SkuAction,
  SkuDTO,
  SkuType,
} from '@invenco/common-interface/products';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ComponentData } from '../../../../shared/types';
import { useGateways } from '../../../../gateways/GatewayProvider';
import { Result } from '../../../../shared/helpers/Result';
import { useEntityDetailsQuery, useGatewayMutation } from '../../../../shared/hooks/queries';
import { useQueryWithInput } from '../../../../shared/hooks/queries/useQueryWithInput';

type Models = {
  isLoading: boolean;
  isLoadingInventories: boolean;
  isLoadingListings: boolean;
  isConverting: boolean;
  isDetailsModalOpen: boolean;
  isPriceModalOpen: boolean;
  isMeasurementsModalOpen: boolean;
  isKitComponentModalOpen: boolean;
  confirmingConversionType?: SkuType;
  canConvert: boolean;
  showComponents: boolean;
  showUnits: boolean;
  showInventories: boolean;
  sku: Partial<SkuDTO>;
  inventories: LocationInventoryDTO[];
  listings: ListingDTO[];
  conversionTypes: SkuType[];
};

type Operations = {
  refresh: () => Promise<void>;
  updateSku: (data: Partial<SkuDTO>) => Promise<Result>;
  startConversion: (type: SkuType) => Promise<void>;
  confirmConversion: () => Promise<void>;
  cancelConversion: () => void;
  openDetailsModal: () => void;
  closeDetailsModal: () => void;
  openPriceModal: () => void;
  closePriceModal: () => void;
  openMeasurementsModal: () => void;
  closeMeasurementsModal: () => void;
  openKitComponentModal: () => void;
  closeKitComponentModal: () => void;
  updateKitComponents: (kitComponents: Partial<KitComponentDTO>[]) => Promise<Result>;
};

const skuTypeToFulfillmentStrategy: { [key in SkuType]: FulfillmentStrategy } = {
  [SkuType.MYSTERY]: FulfillmentStrategy.SELECT,
  [SkuType.KIT]: FulfillmentStrategy.BUNDLE,
  [SkuType.STANDARD]: FulfillmentStrategy.STOCK,
};

export function useSkuDetailsPage(): ComponentData<Models, Operations> {
  const { id } = useParams<{ id: string }>();
  const { productsGateway, inventoryGateway, salesGateway } = useGateways();

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [isMeasurementsModalOpen, setIsMeasurementsModalOpen] = useState(false);
  const [isKitComponentModalOpen, setIsKitComponentModalOpen] = useState(false);
  const [confirmingConversionType, setConfirmingConversionType] = useState<SkuType>();

  const {
    data: sku,
    isLoading,
    refetch,
  } = useEntityDetailsQuery({
    parentKey: 'skus',
    id,
    isNew: false,
    query: (fetchId, { signal }) => productsGateway.getSku(fetchId, { signal }),
  });

  const {
    data: inventories,
    isLoading: isLoadingInventories,
    refetch: refetchInventories,
  } = useQueryWithInput({
    parentKey: 'inventories',
    input: { skuIds: [id!] },
    query: (input, { signal }) => inventoryGateway.getInventories(input, { signal }),
    enabled: !!id,
  });

  const {
    data: listings,
    isLoading: isLoadingListings,
    refetch: refetchListings,
  } = useQueryWithInput({
    parentKey: 'listings',
    input: { skuIds: [id!] },
    query: (input, { signal }) => salesGateway.getListings(input, { signal }),
    enabled: !!id,
  });

  const canConvert = sku?.allowableActions?.includes(SkuAction.CONVERT) ?? false;
  const conversionTypes = Object.values(SkuType).filter((type) => type !== sku?.type);
  const isBundle =
    sku?.fulfillmentStrategy === FulfillmentStrategy.BUNDLE ||
    sku?.fulfillmentStrategy === FulfillmentStrategy.SELECT;
  const showComponents = isBundle;
  const showUnits = !isLoading && !isBundle;
  const showInventories = !isLoading && (!isBundle || (inventories?.items?.length ?? 0) > 0);

  const refresh = async () => {
    await Promise.all([refetch(), refetchListings(), refetchInventories()]);
  };

  const { mutate: updateSku } = useGatewayMutation({
    mutationFn: (data: Partial<SkuDTO>) => productsGateway.updateSku(id!, data),
    linkedQuery: ['skus', id],
    successMessage: 'SKU updated',
  });

  const { mutate: updateKitComponents } = useGatewayMutation({
    mutationFn: (data: Partial<KitComponentDTO>[]) =>
      productsGateway.updateKitComponents(id!, data),
    linkedQuery: ['skus', id],
    successMessage:
      sku?.type === SkuType.MYSTERY ? 'Mystery options updated' : 'Kit components updated',
  });

  const { mutate: convertSku, isPending: isConverting } = useGatewayMutation({
    mutationFn: (type: SkuType) => {
      const fulfillmentStrategy = skuTypeToFulfillmentStrategy[type];
      return productsGateway.convertSku(id!, { fulfillmentStrategy, type });
    },
    linkedQuery: ['skus', id],
    successMessage: 'SKU converted',
  });

  const startConversion = async (type: SkuType) => {
    if (sku?.childKitComponents?.length || sku?.componentGroups?.length) {
      setConfirmingConversionType(type);
    } else {
      await convertSku(type);
    }
  };

  const confirmConversion = async () => {
    if (confirmingConversionType) {
      await convertSku(confirmingConversionType);
      setConfirmingConversionType(undefined);
    }
  };

  return {
    models: {
      isLoading,
      isLoadingInventories,
      isLoadingListings,
      isConverting,
      isDetailsModalOpen,
      isPriceModalOpen,
      isMeasurementsModalOpen,
      isKitComponentModalOpen,
      confirmingConversionType,
      canConvert,
      showComponents,
      showUnits,
      showInventories,
      sku: sku ?? {},
      inventories: inventories?.items ?? [],
      listings: listings?.items ?? [],
      conversionTypes,
    },
    operations: {
      refresh,
      updateSku,
      startConversion,
      confirmConversion,
      cancelConversion: () => setConfirmingConversionType(undefined),
      updateKitComponents,
      openDetailsModal: () => setIsDetailsModalOpen(true),
      closeDetailsModal: () => setIsDetailsModalOpen(false),
      openPriceModal: () => setIsPriceModalOpen(true),
      closePriceModal: () => setIsPriceModalOpen(false),
      openMeasurementsModal: () => setIsMeasurementsModalOpen(true),
      closeMeasurementsModal: () => setIsMeasurementsModalOpen(false),
      openKitComponentModal: () => setIsKitComponentModalOpen(true),
      closeKitComponentModal: () => setIsKitComponentModalOpen(false),
    },
  };
}
