import { AccessContext } from '@invenco/common-interface/auth';
import { ReactNode } from 'react';
import {
  BankOutlined,
  BuildOutlined,
  CodeSandboxOutlined,
  DashboardOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  LineChartOutlined,
  QrcodeOutlined,
  RocketOutlined,
  SettingOutlined,
  ShopOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';

export type NavItem = {
  title: string;
  url: string;
  secondary?: boolean;
  key?: string; // used for explicit deconfliction if needed
  icon?: ReactNode;
  children?: NavItem[];
};

const ITEMS_BY_CONTEXT: Record<AccessContext, NavItem[]> = {
  SELLER: [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: <DashboardOutlined />,
    },
    {
      title: 'Products',
      url: '/products/skus',
      icon: <CodeSandboxOutlined />,
      children: [{ title: 'SKUs', url: '/products/skus' }],
    },
    {
      title: 'Supply',
      url: '/supply/asns',
      icon: <BuildOutlined />,
      children: [{ title: 'ASNs', url: '/supply/asns' }],
    },
    {
      title: 'Inventory',
      url: '/inventory/quantities',
      icon: <QrcodeOutlined />,
      children: [{ title: 'Quantities', url: '/inventory/quantities' }],
    },
    {
      title: 'Sales',
      url: '/sales/orders',
      icon: <DollarOutlined />,
      children: [
        { title: 'Orders', url: '/sales/orders' },
        { title: 'Channels', url: '/sales/channels' },
      ],
    },
    {
      title: 'Automation',
      url: '/automation/workflows',
      icon: <ThunderboltOutlined />,
    },
    {
      title: 'Analytics',
      url: '/analytics/insights',
      icon: <LineChartOutlined />,
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: <SettingOutlined />,
      secondary: true,
    },
  ],
  PLATFORM: [
    {
      title: 'Accounts',
      url: '/accounts',
      icon: <ShopOutlined />,
    },
    {
      title: 'Locations',
      url: '/locations',
      icon: <EnvironmentOutlined />,
    },
    {
      title: 'Shipping',
      url: '/shipping',
      icon: <RocketOutlined />,
      children: [
        { title: 'Carriers', url: '/shipping/carriers' },
        { title: 'Services', url: '/shipping/carrier_services' },
        { title: 'Prices', url: '/shipping/prices' },
        { title: 'Shipments', url: '/shipping/shipments' },
        { title: 'Quotes', url: '/shipping/quotes' },
      ],
    },
    {
      title: 'Bills',
      url: '/bills?view=PENDING',
      icon: <BankOutlined />,
    },
  ],
  WAREHOUSE: [
    { title: 'Tenants', url: '/tenants', icon: <ShopOutlined /> },
    {
      title: 'Automation',
      url: '/automation/workflows',
      icon: <ThunderboltOutlined />,
    },
  ],
  SYSTEM: [],
};

const DEFAULT_ITEMS: NavItem[] = [
  {
    title: 'Dashboard',
    url: '/dashboard',
    icon: <DashboardOutlined />,
  },
];

export const getItems = (context?: AccessContext) =>
  context ? ITEMS_BY_CONTEXT[context] : DEFAULT_ITEMS;
