import { OrderCurrency } from '@invenco/common-interface/sales';

export const DEFAULT_PAGE_TITLE = 'Lynk';

export const DEFAULT_TIME_ZONE = 'Australia/Sydney';
export const DEFAULT_DATE_FORMAT = 'dd-MM-yyyy';
export const DEFAULT_DATETIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DEFAULT_TIME_FORMAT = 'HH:mm:ss';
export const EXPANDED_DATETIME_FORMAT = "do LLLL yyyy 'at' h:mmaaa";

export const DEFAULT_PAGE_SIZE = 50;
export const MAX_AUTO_COMPLETE_RESULTS = 50;
export const DEFAULT_USER_ACCOUNT_LIST_SIZE = 9;

export const SEARCH_DEBOUNCE_TIMEOUT_MS = 300;

export const ORDER_REFRESH_MAXIMUM_CALLS = 40;
export const ORDER_REFRESH_PERIOD_MS = 2000;

export const MENU_COLLAPSED_STORAGE_KEY = 'lynk/menu-collapsed';

export const SUPPORTED_CURRENCIES = [
  OrderCurrency.AUD,
  OrderCurrency.USD,
  OrderCurrency.GBP,
  OrderCurrency.CAD,
  OrderCurrency.NZD,
  OrderCurrency.EUR,
  OrderCurrency.HUF,
  OrderCurrency.SAR,
  OrderCurrency.AED,
  OrderCurrency.JPY,
  OrderCurrency.SGD,
  OrderCurrency.THB,
  OrderCurrency.MYR,
] as const;

export const MAX_TAG_CHARACTERS = 40;

export const NA_TEXT = 'N/A';
