import { AccessContext } from '@invenco/common-interface/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { getLogger } from '../logger/Logger';

const logger = getLogger();

const METADATA_KEY = 'https://lynksupplychain.com/app_metadata';

type TokenPayload = {
  [METADATA_KEY]: {
    access_context: AccessContext;
    account_id: string;
    lynk_user_id: string;
    location_ids: string[];
  };
};

export function useAuthToken() {
  const { user, getAccessTokenSilently, loginWithRedirect, isLoading } = useAuth0();
  const [token, setToken] = useState<string>();
  const [isLoadingToken, setIsLoadingToken] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setIsLoadingToken(true);
    getAccessTokenSilently()
      .then(setToken)
      .catch((e) => {
        logger.error('Error getting auth0 token, redirecting to login', e);
        setToken(undefined);
        void loginWithRedirect();
      })
      .finally(() => {
        setIsLoadingToken(false);
      });
  }, [user, isLoading, getAccessTokenSilently, loginWithRedirect]);

  const decodedToken = useMemo(() => {
    if (!token) return undefined;
    try {
      return (jwtDecode(token) as TokenPayload)[METADATA_KEY];
    } catch (e) {
      logger.error('Error decoding auth0 token', e);
      return undefined;
    }
  }, [token]);

  return {
    token,
    isLoading: isLoading || (!token && isLoadingToken),
    accessContext: decodedToken?.access_context,
    accountId: decodedToken?.account_id,
    userId: decodedToken?.lynk_user_id,
    locationIds: decodedToken?.location_ids,
  };
}
