import { ShippingMethodDTO } from '@invenco/common-interface/shipping';
import {
  ShippingMethodRateCalculationMethod,
  ShippingMethodRateMatchMethod,
  ShippingMethodStatus,
  ShippingMethodAutoInvite,
} from '@invenco/common-domain/enums/ShippingMethod';

import { Input, Select } from 'antd';
import { AddShippingMethodDTO } from '../useLocationDetailsPage';
import { useWarehouseShippingMethodModalComponent } from './useWarehouseShippingMethodModalComponent';
import { FormModal } from '../../../../../components/form-modal';
import { Form } from '../../../../../components/form';
import {
  shippingMethodAutoInviteTitle,
  shippingMethodRateCalculationMethodTitle,
  shippingMethodRateMatchMethodTitle,
} from '../../../../../shared/title-maps';
import { Result } from '../../../../../shared/helpers/Result';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  existingShippingMethods: ShippingMethodDTO[];
  addShippingMethod: (shippingMethod: AddShippingMethodDTO) => Promise<Result>;
  accountId: string;
};

export function WarehouseShippingMethodModal({
  isOpen,
  onClose,
  existingShippingMethods,
  addShippingMethod,
  accountId,
}: Props) {
  const {
    models: {
      form,
      isSaving,
      isLoadingCarriers,
      canSave,
      carrierServices,
      carrierAccounts,
      isLoadingCarrierAccounts,
    },
    operations: { submit, selectCarrierServiceId },
  } = useWarehouseShippingMethodModalComponent({
    isOpen,
    onClose,
    existingShippingMethods,
    addShippingMethod,
    accountId,
  });

  return (
    <FormModal
      onSave={() => form.submit()}
      isSaving={isSaving}
      open={isOpen}
      onCancel={onClose}
      saveTitle="Add"
      saveDisabled={!canSave}
      title="Add Warehouse Shipping Method"
      description={
        !isLoadingCarriers && carrierServices.length === 0
          ? 'All available carrier services have already been added'
          : undefined
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="warehouseShippingMethod"
        onFinish={(data) => void submit(data)}
        initialValues={{
          rateCalculationMethod: ShippingMethodRateCalculationMethod.PER_CONSIGNMENT,
          rateMatchMethod: ShippingMethodRateMatchMethod.STATE_STATE,
          autoInvite: ShippingMethodAutoInvite.MANAGED_LOCATIONS,
        }}
      >
        <Form.Item
          name="carrierServiceId"
          label="Carrier Service"
          rules={[{ required: true, message: 'Please select a carrier service' }]}
        >
          <Select
            showSearch
            loading={isLoadingCarriers}
            placeholder="Select a carrier service"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children as any as string)
                .toLocaleLowerCase()
                .includes(input.toLocaleLowerCase())
            }
            onChange={selectCarrierServiceId}
          >
            {carrierServices.map((service) => (
              <Select.Option key={service.id} value={service.id}>
                {service.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select status' }]}
        >
          <Select>
            {Object.keys(ShippingMethodStatus).map((key) => (
              <Select.Option key={key} value={key}>
                {ShippingMethodStatus[key]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="ratesCarrierAccountId"
          label="Rates Carrier Account"
          rules={[{ required: true, message: 'Please select rates carrier account' }]}
        >
          <Select
            showSearch
            loading={isLoadingCarrierAccounts}
            placeholder="Select a carrier account"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children as any as string)
                .toLocaleLowerCase()
                .includes(input.toLocaleLowerCase())
            }
            onChange={selectCarrierServiceId}
          >
            {carrierAccounts.map((carrierAccount) => (
              <Select.Option key={carrierAccount.id} value={carrierAccount.id}>
                {carrierAccount.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="bookingCarrierAccountId"
          label="Booking Carrier Account"
          rules={[{ required: true, message: 'Please select booking carrier account' }]}
        >
          <Select
            showSearch
            loading={isLoadingCarrierAccounts}
            placeholder="Select a carrier account"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.children as any as string)
                .toLocaleLowerCase()
                .includes(input.toLocaleLowerCase())
            }
            onChange={selectCarrierServiceId}
          >
            {carrierAccounts.map((carrierAccount) => (
              <Select.Option key={carrierAccount.id} value={carrierAccount.id}>
                {carrierAccount.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="rateMatchMethod"
          label="Rate Match Method"
          rules={[{ required: true, message: 'Please select a rate match method' }]}
        >
          <Select>
            {Object.values(ShippingMethodRateMatchMethod).map((rateMatchMethod) => (
              <Select.Option key={rateMatchMethod} value={rateMatchMethod}>
                {shippingMethodRateMatchMethodTitle[rateMatchMethod]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="rateCalculationMethod"
          label="Rate Calculation Method"
          rules={[{ required: true, message: 'Please select a rate calculation method' }]}
        >
          <Select>
            {Object.values(ShippingMethodRateCalculationMethod).map((rateCalculationMethod) => (
              <Select.Option key={rateCalculationMethod} value={rateCalculationMethod}>
                {shippingMethodRateCalculationMethodTitle[rateCalculationMethod]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="autoInvite"
          label="Auto Invite"
          rules={[
            {
              required: true,
              message:
                'Please select what type of locations should be automatically invited to use this method',
            },
          ]}
        >
          <Select>
            {Object.values(ShippingMethodAutoInvite).map((autoInvite) => (
              <Select.Option key={autoInvite} value={autoInvite}>
                {shippingMethodAutoInviteTitle[autoInvite]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </FormModal>
  );
}
