import { Input, Select } from 'antd';
import { Form } from 'components/form';

import { SkuType } from '@invenco/common-interface/products';
import { useCreateSkuModalComponent } from './useCreateSkuModalComponent';
import { FormModal } from '../../../../../components/form-modal';
import { MeasurementFormItems } from '../../MeasurementFormItems';
import { skuTypeTitle } from '../../../../../shared/title-maps';

export function CreateSkuModal({ isOpen, onClose }) {
  const {
    models: { isSaving, form, initialValues },
    operations: { submit },
  } = useCreateSkuModalComponent({ onClose });

  return (
    <FormModal
      open={isOpen}
      isSaving={isSaving}
      onCancel={onClose}
      onSave={() => form.submit()}
      title="Create SKU"
      description="Enter the SKU Name and Description"
    >
      <Form
        form={form}
        layout="vertical"
        name="product"
        onFinish={(data) => void submit(data)}
        initialValues={initialValues}
      >
        <Form.Item
          name="type"
          label="SKU Type"
          rules={[{ required: true, message: 'Please select SKU type' }]}
        >
          <Select>
            {Object.values(SkuType).map((type) => (
              <Select.Option key={type} value={type}>
                {skuTypeTitle[type]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="name"
          label="SKU Name"
          rules={[{ required: true, message: 'Please enter SKU name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter description' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="barcode" label="Barcode">
          <Input />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) =>
            getFieldValue('type') === SkuType.STANDARD && <MeasurementFormItems />
          }
        </Form.Item>
      </Form>
    </FormModal>
  );
}
