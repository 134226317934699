import {
  CreateWarehouseDTO,
  WarehouseDTO,
  WarehouseTenantDTO,
  WmsInstanceDTO,
} from '@invenco/common-interface/warehousing';
import { BaseAxiosGateway, QueryRequestOptions } from '../BaseAxiosGateway';
import { CreateWarehouseTenantDTO, GatewayName, PaginatedResponse } from '../types';

export class AxiosWarehousingGateway extends BaseAxiosGateway {
  name: GatewayName = 'warehousing';

  async getWarehouses(
    query?: {
      take?: number;
      cursor?: string;
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<WarehouseDTO>> {
    const { data } = await this.httpGet('/v1/warehouses', { params: query, ...options });
    return { ...data, items: data.warehouses };
  }

  async createWarehouse(warehouse: CreateWarehouseDTO): Promise<WarehouseDTO> {
    const { data } = await this.httpPost<{ warehouse: WarehouseDTO }>('/v1/warehouses', {
      data: { warehouse },
    });
    return data.warehouse;
  }

  async getWarehouseTenant(
    warehouseTenantId: string,
    options?: QueryRequestOptions,
  ): Promise<WarehouseTenantDTO> {
    const { data } = await this.httpGet(`/v1/warehouse_tenants/${warehouseTenantId}`, options);
    return data.warehouseTenant;
  }

  async getWarehouseTenants(
    query?: {
      take?: number;
      cursor?: string;
      search?: string;
      include?: { warehouse?: boolean; tenant?: boolean };
    },
    options?: QueryRequestOptions,
  ): Promise<PaginatedResponse<WarehouseTenantDTO>> {
    const { data } = await this.httpGet('/v1/warehouse_tenants', {
      params: {
        take: query?.take,
        cursor: query?.cursor,
        q: query?.search,
        includes: query?.include
          ? Object.entries(query.include)
              .filter(([, val]) => val)
              .map(([key]) => key)
              .join(',')
          : undefined,
      },
      ...options,
    });
    return { ...data, items: data.warehouseTenants };
  }

  async createWarehouseTenant(
    warehouseId: string,
    warehouseTenant: CreateWarehouseTenantDTO,
  ): Promise<WarehouseTenantDTO> {
    const { data } = await this.httpPost<{ warehouseTenant: WarehouseTenantDTO }>(
      `/v1/warehouses/${warehouseId}/warehouse_tenants`,
      { data: { warehouseTenant } },
    );
    return data.warehouseTenant;
  }

  async updateWarehouseTenant(
    warehouseTenantId: string,
    warehouseTenant: Partial<WarehouseTenantDTO>,
  ): Promise<WarehouseTenantDTO> {
    const { data } = await this.httpPatch<{ warehouseTenant: WarehouseTenantDTO }>(
      `/v1/warehouse_tenants/${warehouseTenantId}`,
      { data: { warehouseTenant } },
    );
    return data.warehouseTenant;
  }

  async getWmsInstances(
    query?: { include?: { warehouses?: boolean } },
    options?: QueryRequestOptions,
  ): Promise<WmsInstanceDTO[]> {
    const { data } = await this.httpGet<{ wmsInstances: WmsInstanceDTO[] }>('/v1/wms_instances', {
      params: {
        includes: query?.include?.warehouses ? 'warehouses' : undefined,
      },
      ...options,
    });
    return data.wmsInstances;
  }
}
