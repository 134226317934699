import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { KeyCaseType, transformCaseKeys } from '../shared/helpers';
import { GatewayName } from './types';
import { baseUrlProvider } from './baseUrlProvider';

const DEFAULT_VALID_STATUSES = [200, 201, 204] as const;

export type TokenGetter = () => Promise<string>;

export type FullRequestOptions = AxiosRequestConfig & {
  validStatuses?: readonly number[];
};

// only for use with GET requests
// if mutation requests need options in the future, use a MutationRequestOptions type
export type QueryRequestOptions = Pick<FullRequestOptions, 'signal'>;

export abstract class BaseAxiosGateway {
  protected abstract name: GatewayName;
  protected axios: AxiosInstance;
  protected getAccessToken?: TokenGetter;

  constructor(getAccessToken?: TokenGetter) {
    this.axios = axios.create();
    this.getAccessToken = getAccessToken;
  }

  protected httpGet<T = any>(url: string, options?: Partial<FullRequestOptions>) {
    return this.httpRequest<T>({ ...options, url, method: 'get' });
  }

  protected httpPost<T = any>(url: string, options?: Partial<FullRequestOptions>) {
    return this.httpRequest<T>({ ...options, url, method: 'post' });
  }

  protected httpDelete<T = any>(url: string, options?: Partial<FullRequestOptions>) {
    return this.httpRequest<T>({ ...options, url, method: 'delete' });
  }

  protected httpPatch<T = any>(url: string, options?: Partial<FullRequestOptions>) {
    return this.httpRequest<T>({ ...options, url, method: 'patch' });
  }

  protected httpPut<T = any>(url: string, options?: Partial<FullRequestOptions>) {
    return this.httpRequest<T>({ ...options, url, method: 'put' });
  }

  protected async httpRequest<T = any>({
    validStatuses = DEFAULT_VALID_STATUSES,
    ...options
  }: FullRequestOptions) {
    const accessToken = this.getAccessToken && (await this.getAccessToken());
    const response = await this.axios.request<T>({
      baseURL: baseUrlProvider.getForGateway(this.name),
      ...options,
      data: transformCaseKeys(options.data || {}, KeyCaseType.SNAKE),
      params: transformCaseKeys(options.params || {}, KeyCaseType.SNAKE),
      headers: {
        'Content-Type': 'application/json',
        authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        ...options.headers,
      },
    });

    if (!validStatuses.includes(response.status)) {
      throw new Error(
        (response.data as any)?.message || `Unexpected status code: ${response.status}`,
      );
    }
    if (response.data) {
      return { ...response, data: transformCaseKeys(response.data, KeyCaseType.CAMEL) as T };
    }
    return response;
  }
}
