import { ModalProps } from 'antd';
import { Modal } from 'components/modal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { ModalIcon, ModalButtons, ModalTitle, ModalDescription, ModalBody } from './styles';
import { Button } from '../button';

export type ModalConfirmationProps = ModalProps & {
  onCancel: () => void;
  onOk: () => void;
  title?: string;
  description?: ReactNode;
  isSubmitting: boolean;
};

export function ModalConfirm({
  onCancel,
  onOk,
  title = 'Do you want to continue?',
  description,
  isSubmitting = false,
  children,
  ...props
}: ModalConfirmationProps) {
  return (
    <Modal onCancel={onCancel} maskClosable={false} closable={false} footer={null} {...props}>
      <ModalBody>
        <ModalIcon>
          <ExclamationCircleOutlined />
        </ModalIcon>
        <ModalTitle>{title}</ModalTitle>
        {description && <ModalDescription>{description}</ModalDescription>}
        <ModalButtons>
          <Button onClick={onCancel} disabled={isSubmitting} aria-label="Cancel">
            Cancel
          </Button>
          <Button type="primary" onClick={onOk} aria-label="Ok" loading={isSubmitting}>
            Ok
          </Button>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
}
