import { ShippingQuotePackageDTO } from '@invenco/common-interface/shipping';
import { PlusOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import { OrderCurrency } from '@invenco/common-interface/sales';
import {
  Container,
  DetailContainer,
  MainContainer,
  SideContainer,
  VerticallySpaced,
} from '../../../styles/layout';
import { Header } from '../../../components/header';
import { Card } from '../../../components/card';
import { EditableTable } from '../../../components/editable-table';
import { EditableColumn } from '../../../components/editable-table/types';
import { formatMoney, formatNumber } from '../../../shared/helpers';
import { useRequestQuotePage, QuotedRateRow } from './useRequestQuotePage';
import { Button } from '../../../components/button';
import { DetailsCard } from '../../../components/details-card';
import { Form } from '../../../components/form';
import { Radio } from '../../../components/radio';
import { AddressDisplay } from '../../../components/address-display';
import { AddressModal } from './AddressModal';
import { DataTable, Column } from '../../../components/data-table';

const packageColumns: EditableColumn<ShippingQuotePackageDTO>[] = [
  { key: 'name', title: 'Name', editable: false },
  {
    key: 'weight',
    title: 'Weight (KG)',
    required: true,
    align: 'right',
    pattern: 'float',
    render: ({ value }) => formatNumber(value),
    inputValue: ({ weight }) => weight.value,
    autoFocus: true,
    width: '20%',
  },
  {
    key: 'width',
    dataIndex: 'dimensions',
    title: 'Width (CM)',
    required: true,
    align: 'right',
    pattern: 'float',
    render: ({ width }) => formatNumber(width),
    inputValue: ({ dimensions }) => dimensions.width,
    width: '20%',
  },
  {
    key: 'height',
    dataIndex: 'dimensions',
    title: 'Height (CM)',
    required: true,
    align: 'right',
    pattern: 'float',
    render: ({ height }) => formatNumber(height),
    inputValue: ({ dimensions }) => dimensions.height,
    width: '20%',
  },
  {
    key: 'length',
    dataIndex: 'dimensions',
    title: 'Length (CM)',
    required: true,
    align: 'right',
    pattern: 'float',
    render: ({ length }) => formatNumber(length),
    inputValue: ({ dimensions }) => dimensions.length,
    width: '20%',
  },
];

const rateColumns: Column<QuotedRateRow>[] = [
  { key: 'fromLocationName', title: 'From Location' },
  { key: 'carrierName', title: 'Carrier' },
  { key: 'carrierServiceName', title: 'Service' },
  { key: 'shippingMethodName', title: 'Shipping Method' },
  { key: 'deliveryDays', title: 'Delivery Days', align: 'right' },
  {
    key: 'baseCost',
    title: 'Base Cost',
    align: 'right',
    render: (cost, { currency }) => formatMoney(cost, currency as OrderCurrency),
  },
  {
    key: 'totalCost',
    title: 'Total Cost',
    align: 'right',
    render: (cost, { currency }) => formatMoney(cost, currency as OrderCurrency),
  },
];

export function RequestQuote() {
  const {
    models: {
      isRequesting,
      isLoadingAccounts,
      isLoadingLocations,
      isAddressModalOpen,
      canRequest,
      selectedPackageName,
      accounts,
      locations,
      quotedRates,
      requestsCompleted,
      requestsTotal,

      accountId,
      locationId,
      signatureRequired,
      address,
      packages,
    },
    operations: {
      setAccount,
      setLocation,
      setSignatureRequired,
      setAddress,
      selectPackage,
      deselectPackage,
      addNewPackage,
      updatePackage,
      removePackage,
      request,
      openAddressModal,
      closeAddressModal,
    },
  } = useRequestQuotePage();

  return (
    <Container>
      <Header
        title="Request Quote"
        links={[
          { url: '/shipping', title: 'Shipping' },
          { url: '/shipping/quotes', title: 'Quotes' },
        ]}
      />
      <DetailContainer>
        <MainContainer>
          <Card title="Packages" count={packages.length} collapsible>
            <VerticallySpaced $factor={0.5}>
              <EditableTable
                rowKey="name"
                simple
                columns={packageColumns}
                rows={packages}
                deletable
                selectedRowKey={selectedPackageName}
                onClickRow={(row) => selectPackage(row)}
                onCloseRow={deselectPackage}
                onSaveRow={({ name }, data) =>
                  updatePackage(name, +data.weight, {
                    width: +data.width,
                    height: +data.height,
                    length: +data.length,
                  })
                }
                onDeleteRow={({ name }) => removePackage(name)}
              />
              <Button
                aria-label="Add package"
                type="link"
                inline
                icon={<PlusOutlined />}
                onClick={addNewPackage}
              >
                Add package
              </Button>
            </VerticallySpaced>
          </Card>

          <Card
            title="Quoted Rates"
            count={quotedRates?.length}
            rightContent={
              <Button
                type="primary"
                onClick={request}
                disabled={!canRequest}
                loading={isRequesting}
              >
                Request
                {isRequesting && requestsTotal && requestsTotal > 1
                  ? ` (${requestsCompleted}/${requestsTotal})`
                  : ''}
              </Button>
            }
          >
            <DataTable
              simple
              columns={rateColumns}
              rows={quotedRates}
              loading={isRequesting && !quotedRates}
              emptyText={quotedRates ? 'No rates were matched' : 'Make a request to start'}
            />
          </Card>
        </MainContainer>

        <SideContainer>
          <Card title="Details">
            <Form layout="vertical">
              <Form.Item label="Account">
                <Select
                  aria-label="Account"
                  value={accountId}
                  onChange={(id) => setAccount(id)}
                  loading={isLoadingAccounts}
                  disabled={isLoadingAccounts}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? '')
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  options={accounts?.map(({ id, name }) => ({ value: id, label: name }))}
                />
              </Form.Item>

              <Form.Item label="Location">
                <Select
                  aria-label="Location"
                  value={locationId ?? 'ALL'}
                  onChange={(id) => setLocation(id === 'ALL' ? null : id)}
                  loading={isLoadingLocations}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? '')
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  options={[
                    { value: 'ALL', label: 'All' },
                    ...(locations?.map(({ id, name }) => ({ value: id, label: name })) ?? []),
                  ]}
                />
              </Form.Item>

              <Form.Item label="Signature on delivery">
                <Radio.Group
                  onChange={({ target: { value } }) => setSignatureRequired(value === 'yes')}
                  value={signatureRequired ? 'yes' : 'no'}
                >
                  <Space direction="vertical">
                    <Radio value="yes" description="Parcel must be signed for">
                      Signature required
                    </Radio>
                    <Radio value="no" description="Leave in a safe place">
                      Authority to leave
                    </Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Form>
          </Card>

          <DetailsCard title="Address" hasEditButton onEdit={openAddressModal}>
            <AddressDisplay address={address} />
          </DetailsCard>
        </SideContainer>
      </DetailContainer>

      <AddressModal
        open={isAddressModalOpen}
        onClose={closeAddressModal}
        onSave={setAddress}
        address={address}
      />
    </Container>
  );
}
